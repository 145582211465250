<template>
  <div>
    
    

    <div class="col-12">
      <div class="card mb-4">


        <div style="text-align: right; ">
            <b-button 
               variant="outline-primary"    
              @click="toggleFullLayout"
             size="sm"
            >
            <feather-icon
            icon="Maximize2Icon"
            size="12"
          />
          
          </b-button>
        </div>

        <div class="card-header">
          <h4 class="card-title">
            <span>{{RelName}}</span>
          </h4>
     
          <b-col v-if="showFiltro ? false : true ">
            <div style="float:right">
              <div class="DivBotaoLadoLado" >
              <b-button variant="danger" @click="$router.push('../Relatorios')">
                   <span>RETORNAR</span>
               </b-button>
              </div>
               
               <div class="DivBotaoLadoLado" >
               <b-button v-b-toggle="'collapse-filtro'" variant="primary" >
                 <span>FILTROS</span>
               </b-button>
               </div>
            </div>
          </b-col>

        </div>

        <b-collapse visible  id="collapse-filtro">
        <div class="card-body">
          
          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Data Inicial:"
            >
            <b-form-datepicker
                 id="PeridoIni"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoIni"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Data Final:"
            >
            <b-form-datepicker
                 id="PeridoFin"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoFin"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>


              <b-col md="6" xl="3" class="mb-1">
                 <b-form-group
                 label="Data Corte:"
                 >
                 <b-form-datepicker
                      id="DataCorte"
                      locale="pr-BR"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      today-button
                      reset-button
                      v-model="DataCorte"
                      placeholder="Selecione"
                    />
                       </b-form-group>
                 </b-col>
     
            
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Motivo Devoluções:">
                <multiselect
                    v-model="Motivo"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="MotivoOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Vendedor:" >
                <multiselect
                    v-model="CodVend"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodVendOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Coordenador:" >
                <multiselect
                    v-model="CodCoord"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodCoordOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Gerente:" >
                <multiselect
                    v-model="CodGer"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodGerOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>


          
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Região:" >

                  <multiselect
                    v-model="CodReg"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodRegOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

          

          </b-row>

          <b-row>


            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Tipo Consulta:">
                <multiselect
                    v-model="Tipo"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="TipoOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>



            <b-col md="6" xl="3" class="mb-1" >
              <b-form-group label="Status Vendedor/Gerente/Coordenador:" >
                <multiselect
                    v-model="Ativo"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="AtivoOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

              <!--Inicio OS 18856 Ocultado OPCAO de Filtro
              
              <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Possivel Analise Financeira:">
                <multiselect
                    v-model="AnaliseFin"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="AnaliseFinOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>
            
            Fim OS 18856 -->

          </b-row>


        <b-row>
            
      <div class="col-lg-12 col-24">
       <div class="row match-height">

        <div class="col-lg-3 col-md-3 col-6">
          <div 
          @click="ConsultarRelatorio('aFaturar')"
          class="card btn btn-outline-secondary"
          v-b-toggle="'collapse-filtro'"
          >
            <div class="card-body pb-50" style="position: relative;">
              <i class="bi bi-cart-plus" style="font-size: 4rem; color: orange; "></i>
              <br><br>
               <h5 class="fw-bolder mb-1">A Faturar</h5>
            </div>
          </div>

        </div>

          <div class="col-lg-3 col-md-3 col-6">
          <div 
          @click="ConsultarRelatorio('Faturado')"
          class="card btn btn-outline-secondary"
          v-b-toggle="'collapse-filtro'"
          >
            <div class="card-body pb-50" style="position: relative;">
               <i class="bi bi-cart-check" style="font-size: 4rem; color: green; "></i>
               <br><br>
               <h5 class="fw-bolder mb-1">Faturado</h5>
            </div>
          </div>

        </div>

         <div class="col-lg-3 col-md-3 col-6">
          <div 
          @click="ConsultarRelatorio('Devolucao')"
          class="card btn btn-outline-secondary"
          v-b-toggle="'collapse-filtro'"
          >
            <div class="card-body pb-50" style="position: relative;">
               <i class="bi bi-cart-x" style="font-size: 4rem; color: red; "></i>
                <br><br>
               <h5 class="fw-bolder mb-1">Devolução</h5>
            </div>
          </div>

        </div>

         <div class="col-lg-3 col-md-3 col-6">
          <div 
          @click="ConsultarRelatorio('Corte')"
          class="card btn btn-outline-secondary"
          v-b-toggle="'collapse-filtro'"
          >
            <div class="card-body pb-50" style="position: relative;">
               <i class="bi bi-scissors" style="font-size: 4rem; color: orange; "></i>
                <br><br>
               <h5 class="fw-bolder mb-1">Corte</h5>
            </div>
          </div>

        </div>


       </div>

       <div class="row match-height">

        <div class="col-lg-3 col-md-3 col-6">
          <div 
          @click="(ConsultarRelatorio('Entrada'))"
          class="card btn btn-outline-secondary"
          v-b-toggle="'collapse-filtro'"
          >
            <div class="card-body pb-50" style="position: relative;">
              <i class="bi bi-cash-coin" style="font-size: 4rem; color: green; "></i>
              <br><br>
               <h5 class="fw-bolder mb-1">Entrada</h5>
            </div>
          </div>

        </div>

          <div class="col-lg-3 col-md-3 col-6">
          <div 
          @click="ConsultarRelatorio('Bonificacao')"
          class="card btn btn-outline-secondary"
          v-b-toggle="'collapse-filtro'"
          >
            <div class="card-body pb-50" style="position: relative;">
               <i class="bi bi-gift" style="font-size: 4rem; color: red; "></i>
               <br><br>
               <h5 class="fw-bolder mb-1">Bonificação</h5>
            </div>
          </div>

        </div>

         <div class="col-lg-3 col-md-3 col-6">
          <div 
          @click="ConsultarRelatorio('aFaturarProduto')"
          class="card btn btn-outline-secondary"
          v-b-toggle="'collapse-filtro'"
          >
            <div class="card-body pb-50" style="position: relative;">
               <i class="bi bi-cart4" style="font-size: 4rem; color: orange; "></i>
                <br><br>
               <h5 class="fw-bolder mb-1">A Faturar p/ Produto</h5>
            </div>
          </div>

        </div>

         <div class="col-lg-3 col-md-3 col-6">
          <div 
          @click="ConsultarRelatorio('FaturadoProduto')"
          class="card btn btn-outline-secondary"
          v-b-toggle="'collapse-filtro'"
          >
            <div class="card-body pb-50" style="position: relative;">
               <i class="bi bi-cart4" style="font-size: 4rem; color: blue; "></i>
                <br><br>
               <h5 class="fw-bolder mb-1">Faturado p/ Produto</h5>
            </div>
          </div>

        </div>

        <div class="col-lg-3 col-md-3 col-6">
          <div 
          @click="ConsultarRelatorio('DescontoProduto')"
          class="card btn btn-outline-secondary"
          v-b-toggle="'collapse-filtro'"
          >
            <div class="card-body pb-50" style="position: relative;">
               <i class="bi bi-calculator" style="font-size: 4rem; color: orange; "></i>
                <br><br>
               <h5 class="fw-bolder mb-1">Desconto p/ Produto</h5>
            </div>
          </div>

        </div>


       </div>
      </div>

        <b-col
                md="6"
                xl="12"
                class="mb-1"
              >
              <div class="DivBotaoLadoLado" >
               <b-button
              variant="danger"
              type="button"
              @click="$router.push('../Relatorios')"
            >
              <span>RETORNAR</span>
            </b-button>
              </div>
              
          </b-col>


         </b-row>
         
        </div>
        </b-collapse>
        
      </div>
    </div>


      
    <div class="col-12" v-if="TabRelBoletimDiarioVendaPosition >= 0">
            <div class="card mb-4">
              <div class="card-body pb-60" >
                
                
              <div v-if="TabRelBoletimDiarioVendaPosition == 0 && this.TipoConsulta == 'aFaturar'">
                
                <div id="TabRelBDVAnalitico">
                <h4 class="fw-bolder mb-1">Analitico - <b>A Faturar</b></h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelBDVAnalitico"
                  :fields="TabRelBDVAnaliticoAFATURARfields"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                  
                  
                >

                  <template #cell(DTNEG)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTENTSAI)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTMOV)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                    <template #cell(DTPREVENT)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTCORTE)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(VLRNOTA)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(VLRNOTA_SEMST)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(PESO)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>


                 <template #cell(LIMCRED)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(LIMCRED_UTILIZADO)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(SALDO_CREDITO)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>



                <template v-slot:custom-foot>
                    <tr>
                      <td class="bg-secondary text-white" colspan="12">
                       Total
                      </td>
  
                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(TabRelBDVAnaliticoTotal('VLRNOTA'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(TabRelBDVAnaliticoTotal('VLRNOTA_SEMST'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" colspan="6"> 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('PESO'),'money')}}
                      </td>

                    </tr>
                 </template>


                </b-table>
                </div>

                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelBDVAnalitico','')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>
               
              <div v-if="TabRelBoletimDiarioVendaPosition == 0 && this.TipoConsulta == 'Faturado'">
                <h4 class="fw-bolder mb-1">Analitico - <b>Faturado</b></h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelBDVAnalitico"
                  :fields="TabRelBDVAnaliticoFATURADOfields"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                 <template #cell(DTNEG)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTENTSAI)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTMOV)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                    <template #cell(DTPREVENT)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTCORTE)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(VLRNOTA)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(VLRNOTA_SEMST)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(PESO)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(VLRDESCTOTITEM)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(PERC_DESCTOTITEM)="row">
                      {{ TextFormat(row.value,'percent') }}
                 </template>

                  <template v-slot:custom-foot>
                    <tr>
                      <td class="bg-secondary text-white" colspan="12">
                       Total
                      </td>
  
                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(TabRelBDVAnaliticoTotal('VLRNOTA'),'money')}}
                      </td>

                      <td class="bg-secondary text-white"> 
                        {{ 'R$ ' + TextFormat(TabRelBDVAnaliticoTotal('VLRNOTA_SEMST'),'money')}}
                      </td>
    
                      <td class="bg-secondary text-white" colspan="2" > 
                        {{ 'R$ ' + TextFormat(TabRelBDVAnaliticoTotal('VLRDESCTOTITEM'),'money')}}
                      </td>



                      <td class="bg-secondary text-white" colspan="5"> 
                        {{  TextFormat(TabRelBDVAnaliticoTotal('PESO'),'money')}}
                      </td>

                    </tr>
                 </template>

                </b-table>
                
                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelBDVAnalitico','')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>

              <div v-if="TabRelBoletimDiarioVendaPosition == 0 && this.TipoConsulta == 'Devolucao'">
                <h4 class="fw-bolder mb-1">Analitico - <b>Devolução</b></h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelBDVAnalitico"
                  :fields="TabRelBDVAnaliticoDEVOLUCAOfields"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                 <template #cell(DTNEG)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTENTSAI)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTMOV)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                    <template #cell(DTPREVENT)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTCORTE)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(VLRNOTA)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(VLRNOTA_SEMST)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(PESO)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template v-slot:custom-foot>
                    <tr>
                      <td class="bg-secondary text-white" colspan="12">
                       Total
                      </td>
  
                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(TabRelBDVAnaliticoTotal('VLRNOTA'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(TabRelBDVAnaliticoTotal('VLRNOTA_SEMST'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" colspan="7"> 
                        {{  TextFormat(TabRelBDVAnaliticoTotal('PESO'),'money')}}
                      </td>

                    </tr>
                 </template>

                </b-table>
                
                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelBDVAnalitico','')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>
               
              <div v-if="TabRelBoletimDiarioVendaPosition == 0 && this.TipoConsulta == 'Corte'">
                <h4 class="fw-bolder mb-1">Analitico - <b>Corte</b></h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelBDVAnalitico"
                  :fields="TabRelBDVAnaliticoCORTEfields"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                <template #cell(DTNEG)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTENTSAI)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTMOV)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                    <template #cell(DTPREVENT)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTCORTE)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(VLRNOTA)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(VLRNOTA_SEMST)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(PESO)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(QTDCORTE)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(QTDCORTE_CX)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(QTDCORTE_PT)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(VLRCORTE)="row">
                      {{"R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template v-slot:custom-foot>
                    <tr>
                      <td class="bg-secondary text-white" colspan="11">
                       Total
                      </td>
  
                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(TabRelBDVAnaliticoTotal('VLRNOTA'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" colspan="2"> 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('PESO'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('QTDCORTE'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('QTDCORTE_CX'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('QTDCORTE_PT'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" colspan="4" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('VLRCORTE'),'money')}}
                      </td>

                    </tr>
                 </template>

                </b-table>
                
                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelBDVAnalitico','')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>

              <div v-if="TabRelBoletimDiarioVendaPosition == 0 && this.TipoConsulta == 'Entrada'">
                <h4 class="fw-bolder mb-1">Analitico - <b>Entrada</b></h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelBDVAnalitico"
                  :fields="TabRelBDVAnaliticoENTRADAfields"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                <template #cell(DTNEG)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTENTSAI)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTMOV)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                    <template #cell(DTPREVENT)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTCORTE)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(VLRNOTA)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(VLRNOTA_SEMST)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(PESO)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                   <template v-slot:custom-foot>
                    <tr>
                      <td class="bg-secondary text-white" colspan="12">
                       Total
                      </td>
  
                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(TabRelBDVAnaliticoTotal('VLRNOTA'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(TabRelBDVAnaliticoTotal('VLRNOTA_SEMST'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('PESO'),'money')}}
                      </td>

                    </tr>
                 </template>


                </b-table>
                
                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelBDVAnalitico','')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>

              <div v-if="TabRelBoletimDiarioVendaPosition == 0 && this.TipoConsulta == 'Bonificacao'">
                <h4 class="fw-bolder mb-1">Analitico - <b>Bonificação</b></h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelBDVAnalitico"
                  :fields="TabRelBDVAnaliticoBONIFICACAOfields"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                 <template #cell(DTNEG)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTENTSAI)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTMOV)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                    <template #cell(DTPREVENT)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTCORTE)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(VLRNOTA)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(VLRNOTA_SEMST)="row">
                      {{ "R$ " + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(PESO)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template v-slot:custom-foot>
                    <tr>
                      <td class="bg-secondary text-white" colspan="12">
                       Total
                      </td>
  
                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(TabRelBDVAnaliticoTotal('VLRNOTA'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(TabRelBDVAnaliticoTotal('VLRNOTA_SEMST'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" colspan="2" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('PESO'),'money')}}
                      </td>

                    </tr>
                 </template>

                </b-table>
                
                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelBDVAnalitico','')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>

              <div v-if="TabRelBoletimDiarioVendaPosition == 0 && this.TipoConsulta == 'aFaturarProduto'">
                <h4 class="fw-bolder mb-1">Analitico - <b>A Faturar por Produto</b></h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelBDVAnalitico"
                  :fields="TabRelBDVAnaliticoAFATURARPRODUTOfields"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                <template #cell(DTNEG)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTPREVENT)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTCORTE)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>


                 <template #cell(QTDKG)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(BONIFKG)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(QTDCX)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(BONIFCX)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                <template #cell(QTDPT)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(BONIFPT)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(VLRTOT)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(ESTOQUEATUAL)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(FALTAM)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template v-slot:custom-foot>
                    <tr>
                      <td class="bg-secondary text-white" colspan="12">
                       Total
                      </td>
  
                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('QTDKG'),'money')}}
                      </td>
                       
                      <!--
                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('BONIFKG'),'money')}}
                      </td>
                      -->

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('QTDCX'),'money')}}
                      </td>

                      <!--
                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('BONIFCX'),'money')}}
                      </td>
                      -->

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('QTDPT'),'money')}}
                      </td>

                      <!--
                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('BONIFPT'),'money')}}
                      </td>
                      -->

                      <td class="bg-secondary text-white" colspan="3" > 
                        {{ "R$ " + TextFormat(TabRelBDVAnaliticoTotal('VLRTOT'),'money')}}
                      </td>

                      <td class="bg-secondary text-white"  > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('ESTOQUEATUAL'),'money')}}
                      </td>

                      <td class="bg-secondary text-white" colspan="6"  > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('FALTAM'),'money')}}
                      </td>

                      

                    </tr>
                 </template>


                </b-table>
                
                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelBDVAnalitico','')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>

              <div v-if="TabRelBoletimDiarioVendaPosition == 0 && this.TipoConsulta == 'FaturadoProduto'">
                <h4 class="fw-bolder mb-1">Analitico - <b>Faturado por Produto</b></h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelBDVAnalitico"
                  :fields="TabRelBDVAnaliticoFATURADOPRODUTOfields"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                  <template #cell(DTNEG)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTPREVENT)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DTCORTE)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>


                 <template #cell(QTDKG)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(BONIFKG)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(QTDCX)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(BONIFCX)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                <template #cell(QTDPT)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(BONIFPT)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(VLRTOT)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(ESTOQUEATUAL)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(FALTAM)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>


                 <template v-slot:custom-foot>
                    <tr>
                      <td class="bg-secondary text-white" colspan="11">
                       Total
                      </td>
   
                      <!--
                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('BONIFKG'),'money')}}
                      </td>
                      -->

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('QTDCX'),'money')}}
                      </td>

                      <!--
                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('BONIFCX'),'money')}}
                      </td>
                      -->

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('QTDPT'),'money')}}
                      </td>

                      <!--
                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(TabRelBDVAnaliticoTotal('BONIFPT'),'money')}}
                      </td>
                      -->

                      <td class="bg-secondary text-white" colspan="5" > 
                        {{ "R$ " + TextFormat(TabRelBDVAnaliticoTotal('VLRTOT'),'money')}}
                      </td>


                    </tr>
                 </template>


                </b-table>
                
                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelBDVAnalitico','')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>


              <div v-if="TabRelBoletimDiarioVendaPosition == 0 && this.TipoConsulta == 'DescontoProduto'">
                <h4 class="fw-bolder mb-1">Analitico - <b>Desconto por Produto</b></h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelBDVAnalitico"
                  :fields="TabRelBDVAnaliticoDESCONTOPRODUTOfields"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >


                <template #cell(AD_FLEXFIN)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(PRECOBASE)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(UNIT)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                  <template #cell(DTFATUR)="row">
                      {{ TextFormat(row.value,'date') }}
                 </template>

                 <template #cell(DESCONTO)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(QTDNEG)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>
                 
                 

                </b-table>
                
                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelBDVAnalitico','')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>

             </div>
            </div>
    </div>



  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'

import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BCollapse,
  BCard,
  VBToggle,
  BFormDatepicker,
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    Multiselect,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    BFormDatepicker,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      showFiltro: true,
      FiltroRelatorio: [],
      RelName: '',
      RelRota: '',
      TipoConsulta: '',
      TabRelBDVAnalitico: [
      ],
      TabRelBDVAnaliticofields: [
      ],
      TabRelBDVAnaliticoAFATURARfields: [
       { key: 'CODVEND', label: 'Cod.Vendedor', sortable: true, tdClass: 'td100'  },
       { key: 'NOMEVEND', label: 'Vendedor', sortable: false, tdClass: 'td200' },
       
       { key: 'CODPARC', label: 'Cod.Parceiro', sortable: true,tdClass: 'td100' },
       { key: 'NOMEPARC', label: 'Parceiro', sortable: true,tdClass: 'td300' },
       
       { key: 'NUNOTA', label: 'Nº Unico Nota', sortable: true,tdClass: 'td150' },
       { key: 'NUMNOTA', label: 'Nº Nota', sortable: true,tdClass: 'td150' },
       { key: 'PEDIDO_CLIENTE', label: 'Nº Pedido Cliente', sortable: true, tdClass: 'td200'  },
              
       { key: 'DTNEG', label: 'Data Negociação', sortable: true,tdClass: 'td100' },
       { key: 'DTENTSAI', label: 'Data Entrada/Saida', sortable: true,tdClass: 'td100' },
       { key: 'DTMOV', label: 'Data Movimento', sortable: true,tdClass: 'td100' },
       { key: 'DTPREVENT', label: 'Data Prev. Entrega', sortable: true,tdClass: 'td100' },
       { key: 'DTCORTE', label: 'Data Corte', sortable: true,tdClass: 'td100' },
       
       { key: 'VLRNOTA', label: 'Valor Nota', sortable: true,tdClass: 'td200' },
       { key: 'VLRNOTA_SEMST', label: 'Valor Nota sem ST', sortable: true,tdClass: 'td200' },
       
       { key: 'PESO', label: 'Peso', sortable: true,tdClass: 'td200' },        
       
       { key: 'LIMCRED', label: 'Limite Credito', sortable: true,tdClass: 'td200' },
       { key: 'LIMCRED_UTILIZADO', label: 'Limite Credito Utilizado', sortable: true,tdClass: 'td200' },
       { key: 'TITULOS_VENCIDOS', label: 'Titulos Vencidos', sortable: true,tdClass: 'td200' },
       { key: 'SALDO_CREDITO', label: 'Saldo Credito', sortable: true,tdClass: 'td200' },
       
       { key: 'OBSERVACAO', label: 'Observação', sortable: true,tdClass: 'td600' },
        
      ],
      TabRelBDVAnaliticoFATURADOfields: [
        { key: 'CODVEND', label: 'Cod.Vendedor', sortable: true, tdClass: 'td100'  },
        { key: 'NOMEVEND', label: 'Vendedor', sortable: false, tdClass: 'td200' },
        
        { key: 'CODPARC', label: 'Cod.Parceiro', sortable: true,tdClass: 'td100' },
        { key: 'NOMEPARC', label: 'Parceiro', sortable: true,tdClass: 'td300' },
        
        { key: 'NUNOTA', label: 'Nº Unico Nota', sortable: true,tdClass: 'td150' },
        { key: 'NUMNOTA', label: 'Nº Nota', sortable: true,tdClass: 'td150' },
        { key: 'PEDIDO_CLIENTE', label: 'Nº Pedido Cliente', sortable: true, tdClass: 'td200'  },
               
        { key: 'DTNEG', label: 'Data Negociação', sortable: true,tdClass: 'td100' },
        { key: 'DTENTSAI', label: 'Data Entrada/Saida', sortable: true,tdClass: 'td100' },
        { key: 'DTMOV', label: 'Data Movimento', sortable: true,tdClass: 'td100' },
        { key: 'DTPREVENT', label: 'Data Prev. Entrega', sortable: true,tdClass: 'td100' },
        { key: 'DTCORTE', label: 'Data Corte', sortable: true,tdClass: 'td100' },
        
        { key: 'VLRNOTA', label: 'Valor Nota', sortable: true,tdClass: 'td200' },
        { key: 'VLRNOTA_SEMST', label: 'Valor Nota sem ST', sortable: true,tdClass: 'td200' },
        { key: 'VLRDESCTOTITEM', label: 'Valor Desconto', sortable: true,tdClass: 'td200' },
        { key: 'PERC_DESCTOTITEM', label: 'Venda x Desconto %', sortable: true,tdClass: 'td200' },
        
        { key: 'PESO', label: 'Peso', sortable: true,tdClass: 'td200' },        
        
        { key: 'NOMEREG', label: 'Região', sortable: true,tdClass: 'td200' },
        { key: 'NOMECID', label: 'Cidade', sortable: true,tdClass: 'td200' },
        { key: 'NOMEEND', label: 'Endereço', sortable: true,tdClass: 'td300' },        
        
        { key: 'OBSERVACAO', label: 'Observação', sortable: true,tdClass: 'td600' },
        
      ],
      TabRelBDVAnaliticoDEVOLUCAOfields: [
       { key: 'CODVEND', label: 'Cod.Vendedor', sortable: true, tdClass: 'td100'  },
       { key: 'NOMEVEND', label: 'Vendedor', sortable: false, tdClass: 'td200' },
       
       { key: 'CODPARC', label: 'Cod.Parceiro', sortable: true,tdClass: 'td100' },
       { key: 'NOMEPARC', label: 'Parceiro', sortable: true,tdClass: 'td300' },
       
       { key: 'NUNOTA', label: 'Nº Unico Nota', sortable: true,tdClass: 'td150' },
       { key: 'NUMNOTA', label: 'Nº Nota', sortable: true,tdClass: 'td150' },
       { key: 'PEDIDO_CLIENTE', label: 'Nº Pedido Cliente', sortable: true, tdClass: 'td200'  },
              
       { key: 'DTNEG', label: 'Data Negociação', sortable: true,tdClass: 'td100' },
       { key: 'DTENTSAI', label: 'Data Entrada/Saida', sortable: true,tdClass: 'td100' },
	     { key: 'DTMOV', label: 'Data Movimento', sortable: true,tdClass: 'td100' },
	     { key: 'DTPREVENT', label: 'Data Prev. Entrega', sortable: true,tdClass: 'td100' },
       { key: 'DTCORTE', label: 'Data Corte', sortable: true,tdClass: 'td100' },
       
       { key: 'VLRNOTA', label: 'Valor Nota', sortable: true,tdClass: 'td200' },
       { key: 'VLRNOTA_SEMST', label: 'Valor Nota sem ST', sortable: true,tdClass: 'td200' },
       
       { key: 'PESO', label: 'Peso', sortable: true,tdClass: 'td200' },        
       
       { key: 'MOTIVO_FISCAL', label: 'Motivo Fiscal', sortable: true,tdClass: 'td250' },
       { key: 'MOTIVO_COMERCIAL', label: 'Motivo Comercial', sortable: true,tdClass: 'td250' },
       { key: 'MOTIVO_LOGISTICA', label: 'Motivo Logistico', sortable: true,tdClass: 'td250' },
       { key: 'MOTIVO_FABRICA', label: 'Motivo Fabrica', sortable: true,tdClass: 'td250' },
       { key: 'MOTIVO_MARKETING', label: 'Motivo Marketing', sortable: true,tdClass: 'td250' },
       { key: 'MOTIVO_SISTEMA', label: 'Motivo Sistema', sortable: true,tdClass: 'td250' },
        
      ],
      TabRelBDVAnaliticoCORTEfields: [
        
       { key: 'CODVEND', label: 'Cod.Vendedor', sortable: true, tdClass: 'td100'  },
       { key: 'NOMEVEND', label: 'Vendedor', sortable: false, tdClass: 'td200' },
       
       { key: 'CODPARC', label: 'Cod.Parceiro', sortable: true,tdClass: 'td100' },
       { key: 'NOMEPARC', label: 'Parceiro', sortable: true,tdClass: 'td300' },
       
       { key: 'NUNOTA', label: 'Nº Unico Nota', sortable: true,tdClass: 'td150' },
       { key: 'NUMNOTA', label: 'Nº Nota', sortable: true,tdClass: 'td150' },
       { key: 'PEDIDO_CLIENTE', label: 'Nº Pedido Cliente', sortable: true, tdClass: 'td200'  },
              
       { key: 'DTNEG', label: 'Data Negociação', sortable: true,tdClass: 'td100' },
       { key: 'DTENTSAI', label: 'Data Entrada/Saida', sortable: true,tdClass: 'td100' },
	     { key: 'DTMOV', label: 'Data Movimento', sortable: true,tdClass: 'td100' },
	     { key: 'DTPREVENT', label: 'Data Prev. Entrega', sortable: true,tdClass: 'td100' },
       
       { key: 'VLRNOTA', label: 'Valor Nota', sortable: true,tdClass: 'td200' },      
       { key: 'PESO', label: 'Peso', sortable: true,tdClass: 'td200' },        
       
        { key: 'DTCORTE', label: 'Data Corte', sortable: true,tdClass: 'td100' },
        { key: 'QTDCORTE', label: 'KG Corte', sortable: true,tdClass: 'td100' },
        { key: 'QTDCORTE_CX', label: 'CX Corte', sortable: true,tdClass: 'td100' },
        { key: 'QTDCORTE_PT', label: 'PT Corte', sortable: true,tdClass: 'td100' },
        { key: 'VLRCORTE', label: 'R$ Corte', sortable: true,tdClass: 'td100' },

        { key: 'CODPROD', label: 'Cod.Produto', sortable: true,tdClass: 'td100' },
        { key: 'DESCRPROD', label: 'Produto', sortable: true,tdClass: 'td300' },
        
        { key: 'MOTIVO', label: 'Motivo', sortable: true,tdClass: 'td200' },
        
      ],
      TabRelBDVAnaliticoENTRADAfields: [
       { key: 'CODVEND', label: 'Cod.Vendedor', sortable: true, tdClass: 'td100'  },
       { key: 'NOMEVEND', label: 'Vendedor', sortable: false, tdClass: 'td200' },
       
       { key: 'CODPARC', label: 'Cod.Parceiro', sortable: true,tdClass: 'td100' },
       { key: 'NOMEPARC', label: 'Parceiro', sortable: true,tdClass: 'td300' },
       
       { key: 'NUNOTA', label: 'Nº Unico Nota', sortable: true,tdClass: 'td150' },
       { key: 'NUMNOTA', label: 'Nº Nota', sortable: true,tdClass: 'td150' },
       { key: 'PEDIDO_CLIENTE', label: 'Nº Pedido Cliente', sortable: true, tdClass: 'td200'  },
              
       { key: 'DTNEG', label: 'Data Negociação', sortable: true,tdClass: 'td100' },
       { key: 'DTENTSAI', label: 'Data Entrada/Saida', sortable: true,tdClass: 'td100' },
	     { key: 'DTMOV', label: 'Data Movimento', sortable: true,tdClass: 'td100' },
	     { key: 'DTPREVENT', label: 'Data Prev. Entrega', sortable: true,tdClass: 'td100' },
       { key: 'DTCORTE', label: 'Data Corte', sortable: true,tdClass: 'td100' },
       
       { key: 'VLRNOTA', label: 'Valor Nota', sortable: true,tdClass: 'td200' },
       { key: 'VLRNOTA_SEMST', label: 'Valor Nota sem ST', sortable: true,tdClass: 'td200' },
       
       { key: 'PESO', label: 'Peso', sortable: true,tdClass: 'td200' },  
                
      ],
      TabRelBDVAnaliticoBONIFICACAOfields: [
       { key: 'CODVEND', label: 'Cod.Vendedor', sortable: true, tdClass: 'td100'  },
       { key: 'NOMEVEND', label: 'Vendedor', sortable: false, tdClass: 'td200' },
       
       { key: 'CODPARC', label: 'Cod.Parceiro', sortable: true,tdClass: 'td100' },
       { key: 'NOMEPARC', label: 'Parceiro', sortable: true,tdClass: 'td300' },
       
       { key: 'NUNOTA', label: 'Nº Unico Nota', sortable: true,tdClass: 'td150' },
       { key: 'NUMNOTA', label: 'Nº Nota', sortable: true,tdClass: 'td150' },
       { key: 'PEDIDO_CLIENTE', label: 'Nº Pedido Cliente', sortable: true, tdClass: 'td200'  },
              
       { key: 'DTNEG', label: 'Data Negociação', sortable: true,tdClass: 'td100' },
       { key: 'DTENTSAI', label: 'Data Entrada/Saida', sortable: true,tdClass: 'td100' },
	     { key: 'DTMOV', label: 'Data Movimento', sortable: true,tdClass: 'td100' },
	     { key: 'DTPREVENT', label: 'Data Prev. Entrega', sortable: true,tdClass: 'td100' },
       { key: 'DTCORTE', label: 'Data Corte', sortable: true,tdClass: 'td100' },
       
       { key: 'VLRNOTA', label: 'Valor Nota', sortable: true,tdClass: 'td200' },
       { key: 'VLRNOTA_SEMST', label: 'Valor Nota sem ST', sortable: true,tdClass: 'td200' },
       
       { key: 'PESO', label: 'Peso', sortable: true,tdClass: 'td200' },        
       
       { key: 'OBSERVACAO', label: 'Observação', sortable: true,tdClass: 'td600' },
                
      ],
      TabRelBDVAnaliticoAFATURARPRODUTOfields: [
       { key: 'AD_CODESTRAPOLO', label: 'Cód Estruturado', sortable: true, tdClass: 'td100'  },
       { key: 'CODPROD', label: 'Cód Produto', sortable: false, tdClass: 'td100' },
       { key: 'CODPRODALT', label: 'Cód Alternativo', sortable: false, tdClass: 'td100' },
       { key: 'DESCRPROD', label: 'Produto', sortable: true,tdClass: 'td250' },

       { key: 'CODPARC', label: 'Cód Parceiro', sortable: true,tdClass: 'td100' },
       { key: 'NOMEPARC', label: 'Parceiro', sortable: true,tdClass: 'td300' },

       { key: 'NUNOTA', label: 'Nº Unico Nota', sortable: true, tdClass: 'td150'  },
       { key: 'PEDIDO_CLIENTE', label: 'Nº Pedido Cliente', sortable: true, tdClass: 'td200'  },
              
       { key: 'DTNEG', label: 'Data Negociação', sortable: true,tdClass: 'td100' },
       { key: 'DTPREVENT', label: 'Data Prev. Entrega', sortable: true,tdClass: 'td100' },
       { key: 'DTCORTE', label: 'Data Corte', sortable: true,tdClass: 'td100' },
      
       { key: 'CODVOL', label: 'Volume', sortable: true,tdClass: 'td200' },
       { key: 'QTDKG', label: 'Qtd. Kg', sortable: true,tdClass: 'td200' },
       { key: 'BONIFKG', label: 'Bonificado (Kg)', sortable: true,tdClass: 'td200' }.hide,        
       { key: 'QTDCX', label: 'Qtd. Cx', sortable: true,tdClass: 'td200' },
       { key: 'BONIFCX', label: 'Bonificado (Cx)', sortable: true,tdClass: 'td200' }.hide,
       { key: 'QTDPT', label: 'Qtd. Pct', sortable: true,tdClass: 'td200' },
       { key: 'BONIFPT', label: 'Bonificado (Pt)', sortable: true,tdClass: 'td200' }.hide,
       { key: 'VLRTOT', label: 'Vlr. Total', sortable: true,tdClass: 'td200' },
       { key: 'CODLOCALORIG', label: 'Cód Local', sortable: true,tdClass: 'td200' },
       { key: 'DESCRLOCAL', label: 'Local', sortable: true,tdClass: 'td200' },
       { key: 'ESTOQUEATUAL', label: 'Estoque Atual (Kg)', sortable: true,tdClass: 'td200' },
       { key: 'FALTAM', label: 'Qtd Falta (Kg)', sortable: true,tdClass: 'td200' },
       { key: 'CODVEND', label: 'Cód Vendedor', sortable: true,tdClass: 'td200' },
       { key: 'APELIDO', label: 'Vendedor', sortable: true,tdClass: 'td200' },
       { key: 'CODREG', label: 'Cód Região', sortable: true,tdClass: 'td200' },
       { key: 'NOMEREG', label: 'Região', sortable: true,tdClass: 'td200' },
       { key: 'AD_LEADTIME', label: 'Lead Time Parceiro', sortable: true,tdClass: 'td200' },
                
      ],
      TabRelBDVAnaliticoFATURADOPRODUTOfields: [
      
       { key: 'CODPROD', label: 'Cód Produto', sortable: false, tdClass: 'td100' },
       { key: 'DESCRPROD', label: 'Produto', sortable: true,tdClass: 'td250' },
       { key: 'CODPRODALT', label: 'Cód Alternativo', sortable: false, tdClass: 'td100' },

       { key: 'CODPARC', label: 'Cód Parceiro', sortable: true,tdClass: 'td100' },
       { key: 'NOMEPARC', label: 'Parceiro', sortable: true,tdClass: 'td300' },

       { key: 'NUNOTA', label: 'Nº Unico Nota', sortable: true, tdClass: 'td150'  },
              
       { key: 'DTNEG', label: 'Data Negociação', sortable: true,tdClass: 'td100' },
       { key: 'DTPREVENT', label: 'Data Prev. Entrega', sortable: true,tdClass: 'td100' },
       { key: 'DTCORTE', label: 'Data Corte', sortable: true,tdClass: 'td100' },
      
       { key: 'CODVOL', label: 'Volume', sortable: true,tdClass: 'td100' },
       { key: 'CONTROLE', label: 'Controle', sortable: true,tdClass: 'td100' },
       
       { key: 'BONIFKG', label: 'Bonificado (Kg)', sortable: true,tdClass: 'td200' }.hide,        
       { key: 'QTDCX', label: 'Qtd. Cx', sortable: true,tdClass: 'td200' },
       { key: 'BONIFCX', label: 'Bonificado (Cx)', sortable: true,tdClass: 'td200' }.hide,
       { key: 'QTDPT', label: 'Qtd. Pct', sortable: true,tdClass: 'td200' },
       { key: 'BONIFPT', label: 'Bonificado (Pt)', sortable: true,tdClass: 'td200' }.hide,
       { key: 'VLRTOT', label: 'Vlr. Total', sortable: true,tdClass: 'td200' },
       
       
       { key: 'CODVEND', label: 'Cód Vendedor', sortable: true,tdClass: 'td200' },
       { key: 'APELIDO', label: 'Vendedor', sortable: true,tdClass: 'td200' },
       { key: 'CODREG', label: 'Cód Região', sortable: true,tdClass: 'td200' },
       { key: 'NOMEREG', label: 'Região', sortable: true,tdClass: 'td200' },
       
                
      ],
      TabRelBDVAnaliticoDESCONTOPRODUTOfields: [
     
       { key: 'CODPARC', label: 'Cód Parceiro', sortable: true,tdClass: 'td100' },
       { key: 'NOMEPARC', label: 'Parceiro', sortable: true,tdClass: 'td300' },

       { key: 'NUNOTA', label: 'Nº Unico Nota', sortable: true, tdClass: 'td150'  },

       { key: 'CODPROD', label: 'Cód Produto', sortable: false, tdClass: 'td100' },
       { key: 'DESCRPROD', label: 'Produto', sortable: true,tdClass: 'td250' },
       { key: 'CODPRODALT', label: 'Cód Alternativo', sortable: false, tdClass: 'td100' },
       { key: 'AD_CODESTRAPOLO', label: 'Cód Estruturado', sortable: false, tdClass: 'td100' },

       { key: 'CODVOL', label: 'Volume', sortable: true,tdClass: 'td100' },

       { key: 'AD_FLEXFIN', label: 'Rapel', sortable: true,tdClass: 'td100' },
       { key: 'PRECOBASE', label: 'Valor Tabela', sortable: true,tdClass: 'td100' },        
       { key: 'UNIT', label: 'Valor Unitario', sortable: true,tdClass: 'td100' },   

       { key: 'DESCONTO', label: 'Desconto', sortable: true,tdClass: 'td100' },
       { key: 'PERCENTUAL', label: '% Desconto', sortable: true,tdClass: 'td150' },
       
       { key: 'CODVEND', label: 'Cód Vendedor', sortable: true,tdClass: 'td200' },
       { key: 'APELIDO', label: 'Vendedor', sortable: true,tdClass: 'td200' },
       { key: 'CODREG', label: 'Cód Região', sortable: true,tdClass: 'td200' },
       { key: 'NOMEREG', label: 'Região', sortable: true,tdClass: 'td200' },
       
       { key: 'DTFATUR', label: 'Dt. Faturamento', sortable: true,tdClass: 'td100' },
       { key: 'QTDNEG', label: 'Qtd KG Faturado', sortable: true,tdClass: 'td100' },
                
      ],
      


      PeridoIni: '',
      PeridoFin: '',
      DataCorte: '',
      Motivo: { "name": "Comercial", "code": "Comercial" },
      MotivoOption: [
        { name: 'Comercial', code: 'Comercial' },
        { name: 'Fiscal', code: 'Fiscal' },
        { name: 'Logistica', code: 'Logistica' },
        { name: 'Fabrica', code: 'Fabrica' },
        { name: 'Marketing', code: 'Marketing' },
        { name: 'Sistema', code: 'Sistema' },
        { name: 'Todos', code: 'T' },
      ],     
      CodVend: [],
      CodVendOption: [],     
      CodCoord: [],
      CodCoordOption: [],     
      CodGer: [],
      CodGerOption: [],     
      CodReg: [],
      CodRegOption: [],
      Ativo: { "name": "Todos", "code": "T" },
      AtivoOption: [
        { name: 'Ativo', code: 'S' },
        { name: 'Inativo', code: 'N' },
        { name: 'Todos', code: 'T' },
      ],
      Tipo: { "name": "Venda", "code": "Venda" },
      TipoOption: [
        { name: 'Venda', code: 'Venda' },
        { name: 'Bonificação', code: 'Bonificacao' },
        { name: 'Venda Líquida', code: 'VL' },
        { name: 'Amostra', code: 'Amostra' },
      ],
      AnaliseFin: { "name": "Sem Filtro", "code": "S" },
      AnaliseFinOption: [
        { name: 'Sem Filtro', code: 'S' },
        { name: 'Limite Credito', code: 'L' },
        { name: 'Atraso', code: 'A' },
        { name: 'Limite Credito / Atraso', code: 'T' },
      ],

      exportarParaSelected: 'Exporte',
      exportarParaOptions: [
        { value: 'Exporte', text: 'Exporte' },
        { value: 'XLSX', text: 'Excel' },
      ],
      TabRelBoletimDiarioVendaPosition: -1,
    }
  },
  methods: {
    async MostraReport() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data != null) {
            this.RelName = response.data.descricaoRelatorio
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarOpcoes() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Vendedor`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.CodVendOption = response.data
            this.CodCoordOption = response.data
            this.CodGerOption = response.data

          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Regiao`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.CodRegOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarRelatorio(OrigemConsulta) {
      Spinner.StarLoad()

      //Inicio Dados Base Relatorio
      this.showFiltro = false
      const usuario = this.$session.get('usuario')
      let notification = ""
      this.FiltroRelatorio = []
      //Fim Dados Base Relatorio

      //Inicio Limpa Dados Retorno
      this.TabRelBDVAnalitico = []
      this.TabRelBDVAnaliticofields = []
      // Fim Limpa Dados Retorno
      
      //Inicio Volta Todos Niveis para posição inicial
      this.TabRelBoletimDiarioVendaPosition = 0
      //Fim Volta Todos Niveis para posição inicial


      //Inicio Pega parametros de Parametros
       if(this.PeridoIni != "")
       {
         const Filtro = {
           parametro: 'PERIODO_INI',
           valor: this.PeridoIni,
         }
         this.FiltroRelatorio.push(Filtro)
       }

       if(this.PeridoFin != "")
       {
         const Filtro = {
           parametro: 'PERIODO_FIN',
           valor: this.PeridoFin,
         }
         this.FiltroRelatorio.push(Filtro)
       }

       if(this.DataCorte != "")
       {
         const Filtro = {
           parametro: 'DATACORTE',
           valor: this.DataCorte,
         }
         this.FiltroRelatorio.push(Filtro)
       }
      
      if(this.CodVend != null){
        if(Array.isArray(this.CodVend) == false)
        {
          const Filtro = {
            parametro: 'CODVEND',
            valor: this.CodVend.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }

      if(this.CodCoord != null){
         if(Array.isArray(this.CodCoord) == false)
         {
           const Filtro = {
             parametro: 'CODCOORD',
             valor: this.CodCoord.code,
           }
           this.FiltroRelatorio.push(Filtro)
         }
      }
  
      if(this.CodGer != null){
         if(Array.isArray(this.CodGer) == false)
         {
           const Filtro = {
             parametro: 'CODGER',
             valor: this.CodGer.code,
           }
           this.FiltroRelatorio.push(Filtro)
         }
      }
    
      if(this.Motivo != null){
        if(Array.isArray(this.Motivo) == false)
        {
          const Filtro = {
            parametro: 'MOTIVO',
            valor: this.Motivo.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }

      this.CodReg.forEach(Item => {
        const Filtro = {
          parametro: 'CODREG',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })
        
      if(this.Ativo != null){
         if(Array.isArray(this.Ativo) == false)
         {
           const Filtro = {
             parametro: 'ATIVO',
             valor: this.Ativo.code,
           }
           this.FiltroRelatorio.push(Filtro)
         }
      }

      if(this.Tipo != null){
        if(Array.isArray(this.Tipo) == false)
        {
          const Filtro = {
            parametro: 'TIPO',
            valor: this.Tipo.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }

      if(this.AnaliseFin != null){
        if(Array.isArray(this.AnaliseFin) == false)
        {
          const Filtro = {
            parametro: 'ANALISEFIN',
            valor: this.AnaliseFin.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }

      this.TipoConsulta = OrigemConsulta      
      //Fim Pega parametros de Parametros
      

      let ParametroJson = ''
      let URLpost = ''
      

    if(notification === '') {
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelBoletimDiarioVendaAnalitico?CODUSU=${usuario.codusu}&Nivel=${this.TipoConsulta}`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabRelBDVAnalitico = response.data
        

          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })

    }



     if(notification === '') {
       Spinner.EndLoad()
     }

    },
    TabRelBDVAnaliticoTotal(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'VLRNOTA':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.VLRNOTA }, 0.0)
        break

        case 'VLRNOTA_SEMST':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.VLRNOTA_SEMST }, 0.0)
        break

        case 'PESO':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.PESO }, 0.0)
        break

        case 'QTDCORTE':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.QTDCORTE }, 0.0)
        break

        case 'QTDCORTE_CX':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.QTDCORTE_CX }, 0.0)
        break

        case 'QTDCORTE_PT':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.QTDCORTE_PT }, 0.0)
        break

        case 'VLRCORTE':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.VLRCORTE }, 0.0)
        break

        case 'QTDKG':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.QTDKG }, 0.0)
        break

        case 'BONIFKG':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.BONIFKG }, 0.0)
        break

        case 'QTDCX':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.QTDCX }, 0.0)
        break

        case 'BONIFCX':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.BONIFCX }, 0.0)
        break

        case 'QTDPT':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.QTDPT }, 0.0)
        break

        case 'BONIFPT':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.BONIFPT }, 0.0)
        break

        case 'VLRTOT':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.VLRTOT }, 0.0)
        break

        case 'ESTOQUEATUAL':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.ESTOQUEATUAL }, 0.0)
        break

        case 'FALTAM':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.FALTAM }, 0.0)
        break

        case 'VLRDESCTOTITEM':
          Valor = this.TabRelBDVAnalitico.reduce((accum, TabRelBDVAnalitico) => { return accum + TabRelBDVAnalitico.VLRDESCTOTITEM }, 0.0)
        break


       
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TextFormat(valor, tipo) {
      return UtilVUE.TextFormat(valor, tipo)
    },
    ExportarDoc (ElementToExport,DocName) {
      DocName = this.TipoConsulta
      let DataExport = ""
      switch (ElementToExport) {
                case 'TabRelBDVAnalitico':
                DataExport = this.TabRelBDVAnalitico
                break
      }    
      UtilVUE.ExportDoc(this.exportarParaSelected,DocName,ElementToExport,DataExport)
      this.exportarParaSelected = 'Exporte'
      return

    },
    toggleFullLayout() {
      UtilVUE.toggleFullLayout(this.$router, this.$route);
    }
  },
  computed: {
  },
  mounted() {
    if (this.$route.name != null) {
      this.RelRota = this.$route.name
      this.PeridoIni = new Date()
      this.PeridoFin = new Date()
      this.MostraReport()
      this.ConsultarOpcoes()
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>